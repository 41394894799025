import React from "react"

interface types {
	width?: number | string | undefined;
	height?: number | string | undefined;
}

const LineMobile = ({width = 2, height = 524}: types) => {
	return (
		<svg width={width} height={height} viewBox="0 0 2 524" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.3" d="M1 25.5V0M1 524V498.5M1 191.5V166M1 358.5V333" stroke="white" strokeWidth="2"/>
		</svg>

	)
}

export default (LineMobile)
