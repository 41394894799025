import React from "react"

interface types {
	width?: number | string | undefined;
	height?: number | string | undefined;
}

const Line = ({width = 789, height = 2}: types) => {
	return (
		<svg width={width} height={height} viewBox="0 0 789 2" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.3" d="M0.5 1H86.5M238.5 1H319M474 1H550.5M705.5 1H788.5" stroke="white" strokeWidth="2"/>
		</svg>
	)
}

export default (Line)
